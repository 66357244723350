/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useEffect } from "react";
import "./Start.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Progress from "../../components/Progress";
import briefCase from "./../../imgs/briefcase-02.png";
import building from "./../../imgs/building-08.png";
import receipt from "./../../imgs/receipt.png";
import { gaTrigger } from "../../Utils/gaTrigger";
import { ROUTES_PATH } from "../../Utils/routes";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { storeData } from "../../Utils/helper";

function Start() {
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] =
    useState(false);
  const [agreeToReceiveInformation, setAgreeToReceiveInformation] =
    useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("token")) {
      localStorage.setItem("Token", urlParams.get("token"));
    }
    localStorage.removeItem('buyingJourneyOptions')
    navigate("?lang=ar");
  }, []);

  useEffect(() => {
    const cachedAgreedTermsAndConditions = localStorage.getItem(
      "agreedTermsAndConditions"
    );
    const cachedAgreeToReceiveInformation = localStorage.getItem(
      "agreeToReceiveInformation"
    );

    if (cachedAgreedTermsAndConditions) {
      setAgreedTermsAndConditions(JSON.parse(cachedAgreedTermsAndConditions));
    }
    if (cachedAgreeToReceiveInformation) {
      setAgreeToReceiveInformation(JSON.parse(cachedAgreeToReceiveInformation));
    }
  }, []);

  const onNextClick = () => {
    gaTrigger("landing_trigger");
    navigate(ROUTES_PATH.HOME_PURCHASE_INTENTIONS);
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isArabic = i18n.language === "ar";
  const shouldDisable = !agreedTermsAndConditions || !agreeToReceiveInformation;

  const handleAgreements = (index) => {
    if (index === 0) {
      setAgreedTermsAndConditions(!agreedTermsAndConditions);
      storeData("agreedTermsAndConditions", !agreedTermsAndConditions);
    } else {
      setAgreeToReceiveInformation(!agreeToReceiveInformation);
      storeData("agreeToReceiveInformation", !agreeToReceiveInformation);
    }
  };
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={1} />
        <p className="progress-value">0%</p>
      </div>
      <h1 className="header-text">{t("title1")}</h1>
      <div className="info" style={{ width: "100%" }}>
        <p className="info-text">{t("infoTitle")}</p>
        <div className="info-container">
          <div className="row">
            <img src={briefCase} />
            <p>{t("infoItem1")}</p>
          </div>
          <div className="row">
            <img src={building} />
            <p>{t("infoItem2")}</p>
          </div>
          <div className="row">
            <img src={receipt} />
            <p>{t("infoItem3")}</p>
          </div>
        </div>
      </div>
      <p className="text">{t("infoText")}</p>
      {/* {[
        {
          text: t("agree"),
          link: t("termsAndConditions"),
        },
        {
          text: t("agreeSIMAH"),
        },
      ].map((item, index) => (
        <div
          key={index}
          style={{ marginBottom: "10px" }}
          className="checkBoxContainer"
        >
          <Checkbox
            icon={<Icon.FiCheck color="white" size={18} />}
            checked={
              index === 0 ? agreedTermsAndConditions : agreeToReceiveInformation
            }
            onChange={(value, event) => {
              handleAgreements(index);
            }}
            size={16}
            borderColor="#F8F8F8;"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.40)",
              backgroundColor:
                index === 0
                  ? agreedTermsAndConditions
                    ? "#004C51"
                    : "white"
                  : agreeToReceiveInformation
                  ? "#004C51"
                  : "white",
            }}
          />
          <span
            onClick={() =>
              index === 0
                ? setAgreedTermsAndConditions(!agreedTermsAndConditions)
                : setAgreeToReceiveInformation(!agreeToReceiveInformation)
            }
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            {item.text}
          </span>
          <span className="terms-and-conditions">
            <a
              href={
                isArabic
                  ? "/terms-and-conditions-ar.pdf"
                  : "/terms-and-conditions-en.pdf"
              }
              target="_blank"
              style={{
                color: "#004C51",
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              {item.link}
            </a>
          </span>
        </div>
      ))} */}
      <div className="btn-container">
        <button
          // disabled={shouldDisable}
          style={{
            opacity:  "1",
          }}
          className="green-btn"
          onClick={onNextClick}
        >
          {t("btn1")}
        </button>
      </div>
    </div>
  );
}

export default Start;
