import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { RADIO_BUTTONS_NAME } from "./HomePurchaseIntentions/util";
import {
  typesOfIsPropertyReadyOptions,
  typesOfInterested,
} from "../../Utils/options";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import {
  HomeTitle,
  StyledFormControl,
} from "./HomePurchaseIntentions/HomePurchaseIntentions.styled";
import Progress from "../../components/Progress";
import NextButton from "../../components/NextButton";
import BackButton from "../../components/BackButton";
import pay from '../../imgs/Group (2).png'
import google from '../../imgs/G_Pay_Lockup_1_.png'
import card from '../../imgs/credit-card.png'
import axios from "axios";
import { Box } from "@mui/material";
import IframeModal from "./IframeModal";

const Expert = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const selectedOption = watch("selectedOption");  
    useEffect(() => {
      if (selectedOption) {
        console.log("Selected Option (Real-time):", selectedOption);
      }
    }, [selectedOption]); 
    const onSubmit = (data) => {
      if (onSubmitHandler) {
        onSubmitHandler(data);
      } else {
        console.log("Form Data:", data);
      }
    };

  const onSubmitHandler = (data) => {
    console.log("Submitted Data:", data);
    let src_pay;
    if(data.selectedOption == 1){
      src_pay = 'src_apple_pay'  
    } else if(data.selectedOption == 2){
      src_pay ='src_google_pay'
    } else if (data.selectedOption ==3 ) {
      src_pay ='src_card'
    }
    const body = {
      amount : 49, 
      currency: "SAR",
      source: src_pay,
      token : localStorage.getItem("Token")
    }
    axios.post('/tap/charge', body, {
      headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      }).then((e) => {
      const newTab = window.open(e?.data?.transaction?.url, '_blank')
      if (newTab) {
        window.location.href = "about:blank";  
    setTimeout(() => {
      window.close();  
    }, 100);
          } else {
        console.log("Please allow pop-ups to proceed.")
      }
    
    }).catch(e => console.error(e))
    // navigate("/expertOrder")
  }
  const handleOptionChange = (selectedValue) => {
    console.log("Selected Option Changed:", selectedValue);
  };


  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={10} />
      </div>
      <HomeTitle>{t("welcomeMessage")}</HomeTitle>

      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #52B44A",
          width: "95%",
          height: "auto",
          padding: "15px 15px",
        }}
      >
        <h5 style={{ color: "#52B44A", fontWeight: "600" }}>
        {t("text8")}
        </h5>


        <div style={{ marginTop:'15px', display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'10px'}}>
         <h5 style={{ color:'#4D4D4D'}}>
         {t("text9")}
         </h5>
        </div>
      </div>

      <div style={{margin:'25px 0px',  display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
      <h6 style={{ fontWeight: "600", color:'#4D4D4D'}}>
      {t("text10")}
        </h6>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%", marginTop:'10px' }}>
        <StyledFormControl
          component="fieldset"
          error={!!errors.radioGroup}
          sx={{ width: "100%" }}
        >
          {/* <RadioButtons
            value={optionsData[RADIO_BUTTONS_NAME.BUYING_JOURNEY]}
            options={buyingJourneyOptions}
            title={t("exprienece")}
            onChange={handleRadioChange}
            register={register}
            errors={
              errors[RADIO_BUTTONS_NAME?.BUYING_JOURNEY] &&
              t("pleaseAnswerAllRequiredQuestions")
            }
            name={RADIO_BUTTONS_NAME?.BUYING_JOURNEY}
          /> */}


<Box sx={{ display:'flex', gap:'10px'}}>
<RadioButtons
            options={typesOfInterested}
            title={t("text11")}
            register={register}
            name="selectedOption"
            onChange={handleOptionChange}
            errors={errors.selectedOption && t("pleaseAnswerAllRequiredQuestions")}
          />

</Box>








      <div style={{ display:'flex', alignItems:'center', justifyContent:'center', marginTop:'15px' }}>
    <button
          className="green-btn"
          style={{
            background: "transparent",
            opacity: "1",
            cursor: "pointer",
            height: "51px",
            marginTop: "9px",
            width:'288px',
            border:'1px solid #52B44A',
            color:'#004C51',
            fontWeight:'700'
          }}
          type="submit"
    
        >
          {t("text12")}
        {selectedOption == 1&& <img src={pay} alt="Pay"/>}
        {selectedOption == 2&& <img src={google} alt="Pay"/>}
        {selectedOption == 3 && <img src={card} alt="Pay" style={{ width:'27px', marginTop:'2px'}}/>}
        </button>     
      </div>


      <div style={{ display:'flex', alignItems:'center', justifyContent:'center', marginTop:'-20px'}}>
    <button
          className="green-btn"
          style={{
            background: "transparent",
            opacity: "1",
            cursor: "pointer",
            height: "51px",
            marginTop: "0px",
            width:'496px',
            color:'#9F9F9F'
          }}
        >
         {t("text13")}
        </button>     
      </div>
          {/* <div className="btn-container">
        <BackButton disabled={false} url={"/calendartype"} />
        <NextButton type={"submit"} />

      </div> */}
        </StyledFormControl>
      </form>

    </div>
  );
};
export default Expert;
