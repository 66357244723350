import React, { useState } from "react";
import { HomeTitle } from "./HomePurchaseIntentions/HomePurchaseIntentions.styled";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import statics from "../../imgs/image 5.png";
import estate from "../../imgs/layer1.png";
import frame from "../../imgs/Frame.png";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

const ThankYou = () => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(queryString);
  const finishExpert = () => {
    window.location.replace("https://next.aqar.fm/");
    window.location.href = "https://next.aqar.fm/";
    window.location.href = "about:blank";
    window.location.reload();
    window.location.href = "https://next.aqar.fm/";
  };
  return (
    <div className="conatainer">
      <div>
        <div>
          <div
            style={{
              padding: "20px 10px",
              marginTop: "0px",
              width:
                localStorage.getItem("i18nextLng") === "ar" ? "100%" : "100%",
            }}
          >
            <h3 style={{ textAlign: "center", color: "#52B44A" }}>
              {t("text54")}
            </h3>
            <div
              style={{
                marginTop: "5px",
                padding: "20px 10px",
                textAlign: "center",
              }}
            >
              <h5
                style={{
                  textAlign: "center",
                  color: "#2F3A4B",
                  lineHeight: "35px",
                }}
              >
                {t("text55")}
              </h5>

              <div style={{ marginTop: "40px" }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#004C51",
                    marginTop: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {t("text56")}
                </h5>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#004C51",
                    marginTop: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {t("text57")}
                </h5>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#004C51",
                    marginTop: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {t("text58")}
                </h5>
              </div>

              <div style={{ marginTop: "30px", textAlign: "center" }}>
                <img src={frame} alt="" />
              </div>
              {/* <a href="https://next.aqar.fm/" style={{ textDecoration:'none'}}>
<button
              className="green-btn"
              style={{
                background: "transparent",
                opacity: "1",
                cursor: "pointer",
                height: "51px",
                marginTop: "29px",
                width: "100%",
                border: "1px solid var(--Brand-Green, #17B26A)",
                color: "var(--Brand-Green, #17B26A)",
                borderRadius: "10px",
                marginLeft: "10px",
                display: "flex",
              }}
              
              onClick={finishExpert}
              >
              {t("text42")}
            </button>
            </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
