/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import logo from "./../imgs/telegram-cloud-photo-size-2-5291737851903463260-y 1.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./wrappers.css";
import { useEffect } from "react";
import { getData } from "../Utils/helper";
import image from './../imgs/lang.png'

function PrivateWrapper(props) {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  // useEffect(() => {
  //   setToken(localStorage.getItem("Token"));
  //   if (token == null) {
  //     navigate("/workinfo?lang=ar&Token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Miwicm9sZSI6MSwiaWF0IjoxNzE5MTQ1Njc1LCJleHAiOjE3MTkyMzIwNzV9.nQSGPaZL-CPXbck_t5c23fKjpvf6WWjiXPA0g9RTI5c");
  //   } else {
  //     navigate('/');
  //   }
  // }, [window.location.pathname]);

  const cachedAgreedTermsAndConditions = getData("agreedTermsAndConditions");
  const cachedAgreeToReceiveInformation = getData("agreeToReceiveInformation");

  const { i18n } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "ar";
  const setLanguage = (lang = "ar") => {
    i18n.changeLanguage(lang == "ar" ? "ar" : "en");
    localStorage.setItem("i18nextLng", lang);
    navigate(`?lang=${lang}`);
  };

  // useEffect(() => {
  //   if (
  //     !cachedAgreedTermsAndConditions &&
  //     !cachedAgreeToReceiveInformation &&
  //     window.location.pathname !== "/"
  //   ) {
  //     navigate(`/`);
  //   }
  // }, [window.location.pathname]);

  const options = [
    {
      value: "phone",
      selectedBackgroundColor: "white",
      selectedFontColor: "black",
    },
    {
      value: "bar",
      selectedBackgroundColor: "white",
      selectedFontColor: "black",
    },
  ];
  const onChange = (newValue) => {};

  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "bar"
  );
  return (
    <div className="background">
      <div className="header">
        <div
          onClick={() => setLanguage(i18n.language == "ar" ? "en" : "ar")}
          className="languageBar"
          style={{color :"#004C51", display:'flex', marginTop:"20px", gap:'5px'}}
        >
          {" "}
          <p style={{fontSize:'20px', marginTop:'0px'}}>{i18n.language === "en" ? "العربية" : "En"}</p>
     <img src={image} alt='' style={{ width:'20px', height:'18px', marginTop:'5px'}}/>   </div>
      </div>  
      {props.children}
    </div>
  );
}

export default PrivateWrapper;
