import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { RADIO_BUTTONS_NAME } from "./HomePurchaseIntentions/util";
import {
  typesOfIsPropertyReadyOptions,
  typesOfInterestedOfExpertOrder,
} from "../../Utils/options";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import {
  HomeTitle,
  StyledFormControl,
} from "./HomePurchaseIntentions/HomePurchaseIntentions.styled";
import Progress from "../../components/Progress";
import NextButton from "../../components/NextButton";
import BackButton from "../../components/BackButton";
import { TextareaAutosize } from "@mui/material";

const FinishExpert = () => {
  const { t } = useTranslation();

  const finishExpert =  () => {
  window.location.href = "https://next.aqar.fm/";
  };
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={10} />
      </div>
      <HomeTitle>{t("welcomeMessageExpert")}</HomeTitle>

      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #52B44A",
          width: "95%",
          height: "auto",
          padding: "15px 15px",
          backgroundColor:'#52B44A0A'
        }}
      >
        <p style={{ color: "#52B44A", fontWeight: "600" }}>
          {t("thanks")}
        </p>
        <div style={{ marginTop: "20px", padding: "0px 0px" }}>

          <div style={{ display: "flex", gap: "7px" }}>
            <p> {t("expertWillContact")} </p>
          </div>

          <div style={{ display: "flex", gap: "7px", marginTop:'30px' }}>
            <p> {t("maxExpert")} </p>
          </div>


        </div>

      </div>


      <div style={{margin:'20px 5px', width:'100%'}}>
        <h6>           {t("text14")}
      <span style={{ color:'#9F9F9F'}}>
      {t("text15")}
        </span>
        </h6>

        <TextareaAutosize
        minRows={10}
        style={{width:'100%', borderRadius:'15px', marginTop:'25px', border:'1px solid #E1E4EA'}}
        />
      </div>

      <button
          className="green-btn"
          style={{
            background: "var(--Brand-Green, #52B44A)",
            opacity: "1",
            cursor: "pointer",
            height: "51px",
            marginTop: "9px",
          }}
          onClick={finishExpert}
        >
          {t("thanksBtn")}
        </button>
    </div>
  );
};
export default FinishExpert;
