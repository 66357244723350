import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { typesOfInterestedOfExpertOrder } from "../../Utils/options";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import {
  HomeTitle,
  StyledFormControl,
} from "./HomePurchaseIntentions/HomePurchaseIntentions.styled";
import Progress from "../../components/Progress";
import NextButton from "../../components/NextButton";
import BackButton from "../../components/BackButton";

const ExpertOrder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (onSubmitHandler) {
      onSubmitHandler(data);
    } else {
      console.log("Form Data:", data);
    }
  };

  const onSubmitHandler = (data) => {
    console.log("Submitted Data:", data);
      navigate("/finishExpert")
  };
  const handleOptionChange = (selectedValue) => {
    console.log("Selected Option Changed:", selectedValue);
  };

  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={10} />
      </div>
      <HomeTitle>{t("welcomeMessageExpert")}</HomeTitle>

      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #52B44A",
          width: "95%",
          height: "auto",
          padding: "15px 15px",
        }}
      >
        <p style={{ color: "#52B44A", fontWeight: "600" }}>
          {t("serviceDescription")}
        </p>
        <div style={{ marginTop: "20px", padding: "0px 0px" }}>
          <div style={{ display: "flex", gap: "7px" }}>
            <p> {t("serviceFee")} </p>
          </div>

          <div style={{ display: "flex", gap: "7px", marginTop: "30px" }}>
            <p> {t("actionPrompt")} </p>
          </div>

          <div style={{ marginTop: "30px" }}>
            <a target="_blank">
              https://sa.aqar.fm/payment-gateway/munaaseb-fees?value=79
            </a>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", marginTop: "10px" }}
      >
        <StyledFormControl
          component="fieldset"
          error={!!errors.radioGroup}
          sx={{ width: "100%" }}
        >
          <RadioButtons
            options={typesOfInterestedOfExpertOrder}
            title={t("interestQuestion")}
            register={register}
            name="selectedOption"
            onChange={handleOptionChange}
            errors={
              errors.selectedOption && t("pleaseAnswerAllRequiredQuestions")
            }
          />

          <div className="btn-container">
            <BackButton disabled={false} url={"/expert"} />
            <NextButton type={"submit"} />
          </div>
        </StyledFormControl>
      </form>
    </div>
  );
};
export default ExpertOrder;
