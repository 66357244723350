/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getResponce, getData } from "../../Utils/storage";
import { useTranslation } from "react-i18next";
import { setFirstMortgage } from "../../Utils/storage";
import { useParams } from "react-router-dom";
import LimitesContainer from "../../components/LimitesContainer";
import Apartament from "../../components/Apartament";
import BackButton from "../../components/BackButton";
import { HomeTitle } from "./HomePurchaseIntentions/HomePurchaseIntentions.styled";
import Progress from "../../components/Progress";

function CalendarTypes() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const [isEligible, setIsEligible] = useState(null);
  const [selectedBtn, setSelectedBtn] = useState(null);
  // useEffect(()=>{
  //   if(getResponce().max==null){
  //     navigate('/homeownership')
  //   }
  // },[])

  function formatNumberWithCommas(number) {
    return number?.toLocaleString("en-US", { useGrouping: true });
  }
  useEffect(() => {
    const obligationAmount = JSON.parse(
      localStorage.getItem("obligationAmount")
    );
    const eligibleId = JSON.parse(
      localStorage.getItem("isEligible")
    );
    setIsEligible(eligibleId)
    if (obligationAmount == "0") {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);

  return (
    <div className="conatainer">
            <div className="progress-container">
        <Progress done={10} />
      </div>
        <HomeTitle>{t("welcomeMessageExpert")}</HomeTitle>
      {/* <div className="check-icon-wrapper">
        <svg
          width="62"
          height="62"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 31L26.5 40L44.5 22M61 31C61 47.5685 47.5685 61 31 61C14.4315 61 1 47.5685 1 31C1 14.4315 14.4315 1 31 1C47.5685 1 61 14.4315 61 31Z"
            stroke="#52B44A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div> */}
      <div className="last-text-wrapper">
        <p style={{ fontWeight:'bold', fontSize:"30px", textAlign:'center', color:'#4D4D4D' }}>{t("")}</p>
      </div>
      {/* <div className="last-text-wrapper">
        <h1 className='last-text'>{t('title7')}</h1>
        <p>{isEligible == 1 ? t("lastText") : t("lastText2")}</p>
      </div> */}
    
      {/* {isEligible == 1 && ( */}
        <div className="values-icon-container">
          {/* {!hide && (
            <LimitesContainer
              name={t("liabilitiesType1")}
              limit={`${t("Up_to")} ${formatNumberWithCommas(
                getResponce()?.min
              )} ${t("moneyType")}`}
            />
          )} */}
          <LimitesContainer
            name={t("liabilitiesType2")}
            limit={`${t("Up_to")} ${formatNumberWithCommas(
              getResponce()?.max
            )} ${t("moneyType")}`}
          />
        </div>
      {/* )} */}
      {/* <p className='info-text' style={{textAlign:'center',paddingBottom:'0px'}}>Ready to move forward with this property?</p>
        <Apartament img={'https://s3-alpha-sig.figma.com/img/ce89/1166/f72a364b71dbe80b23cfcaac43b3cb85?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bHq3DXYw3O0zkgARxNxlE5y5VvxwGEjGv4ERbA3zXrBBPaIxv8~i9Tw506q6z8saFLGgDGuXhcYh09QCZR8YidiLIokl5PESYS7K5Wo~ju4iA1SO3wRKkFdrbdw81KnhgjTaMZTTHrx6j-XqSh4w79HcWtYWd2LNgjM604BXuat4d1aK6z2qk8rF-d81gE8l6QL0lORx~UetPAK0SXOq01Voj21I1j-9xhtGXvL7UOhmhKTMptALa-mG27OKDTcprVmWdpn4dgJyzgJtgSvogtqcgT7lwS0~WaFTP1O3YoKjw9mKoBJjv94dTiGVWOM8VVZnnbaGyAOvlpTQr4Lgaw__'} title={'Apartment for sale in Tawfiq Hussein Street, Al-Rabwah District, Jeddah'} price={'1,200,000 SAR '} companyName={'KSA Properties LLC'}/>
       {getResponce()?.min==0?<div className='last-info-wrapper'>
           <p >{t('text2')}</p></div>:null}
        {/* <div className='last-info-wrapper'>
           <p >{t('text2')}</p>
           <p>{t('text3')}</p>
        </div> */}
      {/* <div className='btn-container' style={{flexDirection:'column',gap:'12px'}}><button className='green-btn' onClick={()=>{ */}
      {/* // localStorage.clear()
          // navigate('/confirmation')}}>{t('Proceed with Selected Property')}</button> */}
      {/* <button className='white-btn'  onClick={()=>navigate('/findproperty')} >{t('Assist Me in Finding a Property')}</button>  */}
      {/* </div> */}
    </div>
  );
}

export default CalendarTypes;
