import React from 'react';
import {Box, Radio} from '@mui/material';
import {StyledFormControlLabel, StyledFormControl, StyledRadioGroup, StyledFormLabel} from "./RadioButton.styled";
import {useTranslation} from "react-i18next";
const RadioButtons = ({value, options,  title, onChange, name = '',register,errors}) => {
  const {t} = useTranslation()
  return (
    <StyledFormControl>
      <StyledFormLabel>{title}</StyledFormLabel>
      <StyledRadioGroup
        value={value}
        name="radio-buttons-group"
        onChange={(e) => {
          onChange(name, e.target.value)
        }}
      >
        {options.map((option) => {
          return(
            <>
{
  !(option.img) ?         
      <StyledFormControlLabel
  isSelected={value === option.value}
  key={option.value}
  value={option.value}
  control={<Radio/>}
  {...register(name, { required: `${title} is required` })} // react-hook-form register
  label={t(option.label)}
/>

: <Box sx={{display:'flex', gap:'30px'}}>
  <StyledFormControlLabel
    isSelected={value === option.value}
    key={option.value}
    value={option.value}
    control={<Radio/>}
    {...register(name, { required: `${title} is required` })} // react-hook-form register
    label={t(option.label)}
  />
  <img src={option.img} alt={t(option.label)} style={{ width:'40px', marginTop:'10px', height:'30px'}} />
 
</Box>
}
             </>
          )})}
         
      </StyledRadioGroup>
      {errors && <p style={{ color: 'red', marginTop:'12px' }}>{errors}</p>}
    </StyledFormControl>
  );
};
export default RadioButtons;
