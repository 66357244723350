import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import statics from '../imgs/image 5.png'
import money from '../imgs/money-bag 1.png'
import estate from '../imgs/layer1.png'
import image from '../imgs/24-Tax.png'

function LimitesContainer({ name, limit }) {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const startExpert = ()=>{
    navigate('/expert')
  }
  return (
    <div className="limit-container">


<div className="limit-container" style={{backgroundColor:'#ECFC3C1A', padding:'20px 15px', borderRadius:'15px' }}>
       <p>{name}</p>
      <h2>{limit}</h2>   
</div>
 
{/* 
      <div style={{ border:'1px solid #52B44A', borderRadius:'10px' , padding:'15px', marginTop:'20px'}}>


        <div>
          <h6 style={{ fontWeight:'600', color:'#52B44A', }}>
            
           {t("text1")} <img src={money} alt="" />
           </h6>
        </div>
        <div style={{ marginTop: "20px", padding: "0px 15px" }}>

          <div style={{ display: "flex", gap: "7px" }}>
            <p style={{ fontSize: "20px" }}>.</p>
            <p> {t("benefitsList.text1")} </p>
          </div>


          <div style={{ display: "flex", gap: "7px" }}>
            <p style={{ fontSize: "20px" }}>.</p>
            <p> {t("benefitsList.text2")} </p>
          </div>

          <div style={{ display: "flex", gap: "7px" }}>
            <p style={{ fontSize: "20px" }}>.</p>
            <p> {t("benefitsList.text3")} </p>
          </div>
        </div>


        <div style={{marginTop:'35px'}}>
          <p>
          {t("text2")}    </p>
        </div>


<div style={{marginTop:'20px'}}>

  <h6 style={{  color:'#52B44A' }}>
  {t("text3")}
  </h6>



  <div style={{ marginTop: "20px", padding: "0px 15px" }}>

<div style={{ display: "flex", gap: "7px" }}>
  <p style={{ fontSize: "20px" }}>.</p>
  <p> {t("text4")}  <img src={statics} alt="" style={{ marginBottom:'10px'}}/>

  </p>
</div>


<div style={{ display: "flex", gap: "7px", marginTop:'15px' }}>
  <p style={{ fontSize: "20px" }}>.</p>
  <p> 
  {t("text5")}  <img src={estate} alt="" />
    </p>
</div>

<div style={{ display: "flex", gap: "7px", marginTop:'15px' }}>
  <p style={{ fontSize: "20px" }}>.</p>
  <p> {t("text6")}  <img src={image} alt="" style={{ marginBottom:'5px'}}/>   </p>
</div>
</div>




</div>





      </div>
      <div style={{ display:'flex', alignItems:'center', justifyContent:'center', marginTop:'15px' }}>
    <button
          className="green-btn"
          style={{
            background: "var(--Brand-Green, #52B44A)",
            opacity: "1",
            cursor: "pointer",
            height: "51px",
            marginTop: "9px",
            width:'496px'
          }}
          onClick={startExpert}
        >
          {t("start")}
        </button>     
      </div>


      <div style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
    <button
          className="green-btn"
          style={{
            background: "transparent",
            opacity: "1",
            cursor: "pointer",
            height: "51px",
            marginTop: "9px",
            width:'496px',
            color:'#9F9F9F'
          }}
        >
        {t("text7")}
        </button>     
      </div> */}

    </div>
  );
}

export default LimitesContainer;
