import React, { useState, useRef } from 'react';
import { FormControl, Select, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

function InputSelect({ name, placeHolder, changeValue, options, value, style }) {
    const [open, setOpen] = useState(false);  

    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            borderColor: '#52B44A',
                        },
                    },
                },
            },
        },
    });

    const handleIconClick = () => {
        setOpen((prev) => !prev); 
    };

    const handleChange = (event) => {
        changeValue(event.target.value);
        setOpen(false);  
    };

    return (
        <div className="select-label">
            <p>{name}</p>
            <FormControl fullWidth>
                <Select
                    id="my-select"
                    theme={theme}
                    value={value}
                    onChange={handleChange}
                    open={open}  
                    onClose={() => setOpen(false)} 
                    onOpen={() => setOpen(true)}  
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={handleIconClick}>
                                {/* <span style={{ fontSize: '20px' }}>▼</span> */}
                            </IconButton>
                        </InputAdornment>
                    }
                >
                    {placeHolder && <MenuItem value="">{placeHolder}</MenuItem>}
                    {options?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default InputSelect;
