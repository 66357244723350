export function convertToNumber(str) {
  if (!str || typeof str !== "string") return;
  // Remove commas using replace and convert the string to a number using Number()
  const number = Number(str?.replace(/,/g, ""));
  return number;
}

export function storeData(key, data) {
  // Convert the data to a string using JSON.stringify() and store it in the localStorage
  localStorage.setItem(key, JSON.stringify(data));
}

export function getData(key) {
  // Retrieve the data from the localStorage and parse it as JSON
  const data = localStorage.getItem(key);
  return JSON.parse(data);
}
