export const RADIO_BUTTONS_NAME = {
  BUYING_JOURNEY :"buyingJourney",
  BUYING_TIMELINE :"buyingTimeline",
  USAGE_PLAN :"usagePlan",
  IS_PROPERTY_READY:'hasProperty'
}



export const RADIO_BUTTONS_NAME_EXPERT = {
    EXPERT_PAYMENT :"expertPayment",
  IS_PROPERTY_READY:'hasProperty'
}
